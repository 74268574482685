/* eslint-disable */
import React, { Component } from "react";
import { validated } from "react-custom-validation";
import validator from "validator";
import { includes } from "lodash";
import { appId, apiUrl, mapcountry } from "../Helpers/Config";

const isEmpty = (value) => (value === "" ? "This field is required." : null);

var currentAppId = [
  "93EDF976-7242-4864-B57E-7B49306521AC",
  "E66D5AAF-9077-4885-B379-02D3FCB8BB16",
];

function validationConfig(props) {
  const { email, pin } = props.fields;

  return {
    fields: ["email", "pin"],

    validations: {
      email: [[isEmpty, email]],
      pin: [[isEmpty, pin]],
    },
  };
}

class Login extends Component {
  constructor(props) {
    super(props);
  }

  openGuest() {
    $.magnificPopup.open({
      items: {
        src: "#guest-checkout-popup",
      },
      type: "inline",
    });
  }

  render() {
    const { fields, onChange, onValid, onInvalid, $field, $validation } =
      this.props;
    let errMsgEmail, errMsgPin;

    if ($validation.email.error.reason !== undefined) {
      errMsgEmail = $validation.email.show && (
        <span className="error">{$validation.email.error.reason}</span>
      );
    }
    if ($validation.pin.error.reason !== undefined) {
      errMsgPin = $validation.pin.show && (
        <span className="error">{$validation.pin.error.reason}</span>
      );
    }

    return (
      <div className="popup-body">
        <span className="spn-error"></span>
        <div className="form-group">
          {includes(currentAppId, appId) === true && (
            <div className="focus-out focused">
              <label>Enter Mobile Number / Email</label>
              <input
                type="text"
                className="form-control input-focus"
                placeholder="09XXXXXXXXX"
                value={fields.email}
                {...$field("email", (e) => onChange("email", e.target.value))}
              />
              {errMsgEmail}
            </div>
          )}

          {includes(currentAppId, appId) === false && (
            <div className="focus-out">
              <label>Enter Mobile Number</label>
              <input
                type="text"
                className="form-control input-focus"
                value={fields.email}
                {...$field("email", (e) => onChange("email", e.target.value))}
              />
              {errMsgEmail}
            </div>
          )}
        </div>
        <div className="form-group">
          <div className="focus-out">
            <label>Enter Your Password</label>

            <input
              type="password"
              className="form-control input-focus"
              value={fields.pin}
              {...$field("pin", (e) => onChange("pin", e.target.value))}
            />
            {errMsgPin}
          </div>
        </div>
        <div className="form-group">
          <div className="login_pop_sub">
            <button
              className="button btn_black btn_minwid login_submit"
              onClick={(e) => {
                e.preventDefault();
                this.props.$submit(onValid, onInvalid);
              }}
            >
              {" "}
              {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2"
                ? "Login"
                : "Submit"}{" "}
            </button>
          </div>
        </div>

        <div className="form-group">
          <div className="controls two-links">
            <a
              href="#signup-popup"
              data-effect="mfp-zoom-in"
              className="open-popup-link"
            >
              {" "}
              Create an account
            </a>
            <span className="spilter"> </span>
            <a href="#forgot-password-popup" className="open-popup-link">
              Forgot Password
            </a>
            {this.props.guestcheckoutenable == "1" && (
              <span className="spilter"> </span>
            )}
            {this.props.guestcheckoutenable == "1" && (
              <a href={void 0} onClick={this.openGuest.bind(this)}>
                {appId == "D4A1F6D0-A140-418F-BBBB-56BF5A24B2E2"
                  ? "Guest Login"
                  : "PROCEED AS GUEST"}
              </a>
            )}{" "}
          </div>
        </div>
      </div>
    );
  }
}
Login = validated(validationConfig)(Login);

export default Login;
