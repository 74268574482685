import { SET_MENU_NAVIGATION } from "../actions";

const productmmenunav = (state = [], action) => {
  switch (action.type) {
    case SET_MENU_NAVIGATION: {
      var productsArr = [];
      productsArr["productlist"] = [];
      productsArr["menuNavigation"] = action.value;
      var resultsArr = [];
      resultsArr.push(productsArr);
      return [...resultsArr];
    }
    default:
      return state;
  }
};

export default productmmenunav;
