/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel2";
import "../../../common/css/owl.carousel.css";
import { stripslashes } from "../../Helpers/SettingHelper";
import { GET_BLOG } from "../../../actions";
import blogImg from "../../../common/images/blog-placeholder.jpg";

class Blog extends Component {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    this.props.getBlogList();
  }
  render() {
    let blogArr = this.props.blog;
    let bloglist = [];
    let blogimagesource = "";

    if (Object.keys(blogArr).length > 0) {
      if (blogArr[0].status === "ok") {
        bloglist = blogArr[0].result_set;
        blogimagesource = blogArr[0].common.image_source;
      }
    }

    var settingsBlog = {
      items: 3,
      autoplay: false,
      margin: 25,
      loop: true,
      center: true,
      nav: false,

      stagePadding: 80,
      responsive: {
        0: {
          items: 1,
          margin: 0,
          stagePadding: 0,
        },
        580: {
          items: 1,
          margin: 10,
          stagePadding: 0,
        },
        780: {
          items: 2,
          margin: 10,
          stagePadding: 20,
        },
        980: {
          items: 2,
          margin: 15,
          stagePadding: 20,
        },
        1200: {
          items: 3,
        },
      },
    };

    return (
      <>
        {Object.keys(bloglist).length > 0 && (
          <div className="home-blog-slider">
            <OwlCarousel options={settingsBlog}>
              {bloglist.map((item, index) => {
                return (
                  <div key={index}>
                    <div className="home-blog-inner">
                      <div className="home-blog-img">
                        <a href="#">
                          <img
                            src={
                              item.blog_image !== "" && item.blog_image !== null
                                ? blogimagesource + item.blog_image
                                : blogImg
                            }
                            alt={stripslashes(item.blog_title)}
                          />
                        </a>
                      </div>
                      <div className="home-blog-content">
                        <h5>
                          <Link to={"#"}> {stripslashes(item.blog_title)}</Link>
                        </h5>
                        {item.blog_short_description !== "" &&
                          item.blog_short_description !== null && (
                            <p>{stripslashes(item.blog_short_description)}</p>
                          )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </OwlCarousel>
          </div>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  return {
    blog: state.blog,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogList: () => {
      dispatch({ type: GET_BLOG });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Blog);
