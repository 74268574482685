/* eslint-disable */
import ReactGA from "react-ga";
import cookie from "react-cookies";
import ReactPixel from "react-facebook-pixel";

export function loadAnalytics() {
  var gaID = typeof cookie.load("ga_id") !== null ? cookie.load("ga_id") : "";
  var fbPixelID =
    typeof cookie.load("fb_pixel_id") !== null
      ? cookie.load("fb_pixel_id")
      : "";

  if (gaID) {
    ReactGA.initialize(gaID);
    ReactGA.pageview(window.location.hash);
  }

  if (fbPixelID) {
    const options = {
      autoConfig: true,
      debug: false,
    };

    ReactPixel.init(fbPixelID, {}, options);
    ReactPixel.pageView();
  }
}

export function fbPixelCustomEvent(event, data) {
  console.log(data, "datadata");
  var fbPixelID =
    typeof cookie.load("fb_pixel_id") !== null
      ? cookie.load("fb_pixel_id")
      : "";

  if (fbPixelID) {
    const options = {
      autoConfig: true,
      debug: false,
    };

    ReactPixel.init(fbPixelID, {}, options);
    ReactPixel.trackCustom(event, data);
    console.log(ReactPixel, "ReactPixel");
  }
}
