/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import Moment from "moment";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { format } from "date-fns";
import {
  stripslashes,
  addressFormat,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  appId,
  deliveryId,
  apiUrlNotify,
  dineinId,
  cateringId,
  apiUrl,
} from "../Helpers/Config";
import scootyImg from "../../common/images/delivery-bike.svg";
import cartMpImg from "../../common/images/cart-map.png";
import sending from "../../common/images/sending.gif";
import not_find_driver from "../../common/images/not_find_driver.gif";

import thanku from "../../common/images/Thankyou.gif";
import paymentg from "../../common/images/payment.gif";
import { GET_ORDER_DETAIL, DESTROY_CART_DETAIL } from "../../actions";
var qs = require("qs");
class Findingdriver extends Component {
  constructor(props) {
    super(props);
    let orderId =
      typeof this.props.match.params.orderId !== "undefined"
        ? this.props.match.params.orderId
        : "";
    this.state = {
      step: 1,
      count: 1,
      order_primary_id: "",
      compnay_user_id: "",
      enableRetry: false,
      starttoRetry: false,
      currentparenter: "",
      settingsArr: [],
      orderId: orderId,
      startSearching: true,
      parenterList: [],
      parenterassignList: [],
    };
  }

  componentWillMount() {
    if (this.state.orderId !== "") {
      this.props.getOrderDetail(this.state.orderId);
    } else {
      showAlert("Error", "Invalid order detail.");
      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
      this.props.history.push("/");
    }
  }

  componentWillReceiveProps(PropsDt) {
    if (this.state.settingsArr !== PropsDt.settingsArr) {
      this.setState({ settingsArr: PropsDt.settingsArr }, function () {
        var startSearching = this.state.startSearching;
        if (
          startSearching === true &&
          Object.keys(PropsDt.settingsArr).length > 0
        ) {
          var settings_Arr = PropsDt.settingsArr;
          var parenterList = [];
          if (settings_Arr.client_enable_lalmove_api === "1") {
            parenterList.push("lalamove");
          }
          if (
            settings_Arr.grab_express_enable !== "" &&
            typeof settings_Arr.grab_express_enable !== undefined &&
            typeof settings_Arr.grab_express_enable !== "undefined" &&
            settings_Arr.grab_express_enable === "1"
          ) {
            parenterList.push("grab");
          }
          console.log(PropsDt.settingsArr, parenterList, "PropsDt.settingsArr");
          if (parenterList.length > 0) {
            var parenterassignList = [parenterList[0]];
            this.setState(
              {
                startSearching: false,
                parenterList: parenterList,
                parenterassignList: parenterassignList,
                currentparenter: parenterList[0],
              },
              function () {
                console.log(
                  this.state.parenterassignList,
                  this.state.currentparenter,
                  "parenterassignList"
                );
                this.autoassignpartner(this.state.orderId, parenterList[0]);
              }
            );
          }
        }
      });
    }
  }

  componentDidMount() {}
  autoassignpartner(orderId, deliverypartner) {
    this.setState({ count: 1 }, function () {
      axios
        .get(
          apiUrl +
            "deliverypartner/autoassignpartner?app_id=" +
            appId +
            "&partner=" +
            deliverypartner +
            "&order_local_no=" +
            orderId
        )
        .then((res) => {
          if (res.data.orderPrimaryID !== "") {
            this.setState({
              order_primary_id: res.data.orderPrimaryID,
              compnay_user_id: res.data.user_id,
            });
          }
          this.checkingRaider(orderId);
        });
    });
  }

  checkingRaider(orderId) {
    axios
      .get(
        apiUrl +
          "deliverypartner/raiderOrderDetails?order_id=" +
          orderId +
          "&app_id=" +
          appId +
          "&partner=" +
          this.state.currentparenter
      )
      .then((res) => {
        console.log(res, "resresres");
        var currentThis = this;
        if (res.data.status === "success") {
          this.setState({ step: 2 }, function () {
            setTimeout(function () {
              currentThis.props.history.push("/thankyou/" + orderId);
            }, 5000);
          });
        } else {
          this.setState({ count: parseInt(this.state.count) + 1 }, function () {
            console.log(this.state.count, "this.state.count");
            if (this.state.count <= 30) {
              setTimeout(function () {
                currentThis.checkingRaider(orderId);
              }, 3000);
            } else {
              if (this.state.currentparenter === "lalamove") {
                this.cancellalamove();
              }
              if (this.state.currentparenter === "grab") {
                this.cancelgrab();
              }
            }
          });
        }
      });
  }

  cancellalamove() {
    axios
      .get(
        apiUrl +
          "deliverypartner/change_lalamove_status?order_id=" +
          this.state.orderId +
          "&app_id=" +
          appId
      )
      .then((res) => {
        var arr1 = this.state.parenterList;
        var arr2 = this.state.parenterassignList;
        var difference = arr1.filter((x) => !arr2.includes(x));
        if (difference.length > 0) {
          var parenterassign_List = this.state.parenterassignList;
          parenterassign_List.push(difference[0]);
          this.setState(
            {
              parenterassignList: parenterassign_List,
              currentparenter: difference[0],
            },
            function () {
              this.autoassignpartner(this.state.orderId, difference[0]);
            }
          );
        } else {
          this.setState(
            { enableRetry: true, starttoRetry: false },
            function () {
              console.log(res, "enable grab retry");
            }
          );
        }
      });
  }
  cancelgrab() {
    axios
      .get(
        apiUrl +
          "deliverypartner/change_grab_status?order_id=" +
          this.state.orderId +
          "&app_id=" +
          appId
      )
      .then((res) => {
        var arr1 = this.state.parenterList;
        var arr2 = this.state.parenterassignList;
        var difference = arr1.filter((x) => !arr2.includes(x));
        if (difference.length > 0) {
          var parenterassign_List = this.state.parenterassignList;
          parenterassign_List.push(difference[0]);
          this.setState(
            {
              parenterassignList: parenterassign_List,
              currentparenter: difference[0],
            },
            function () {
              this.autoassignpartner(this.state.orderId, difference[0]);
            }
          );
        } else {
          this.setState(
            { enableRetry: true, starttoRetry: false },
            function () {
              console.log(res, "enable grab retry");
            }
          );
        }
      });
  }

  assignretry() {
    var parenterList = this.state.parenterList;
    if (parenterList.length > 0) {
      var newparenterassignList = [parenterList[0]];
      this.setState(
        { parenterassignList: newparenterassignList, starttoRetry: true },
        function () {
          this.autoassignpartner(this.state.orderId, parenterList[0]);
        }
      );
    }
  }
  cancelOrder() {
    if (
      this.state.order_primary_id !== "" &&
      this.state.compnay_user_id !== ""
    ) {
      var postObject = {
        app_id: appId,
        order_primary_id: this.state.order_primary_id,
        order_status: "5",
        logged_id: this.state.compnay_user_id,
      };

      axios
        .post(
          apiUrl + "businesscall/change_orderstatus_popup",
          qs.stringify(postObject)
        )
        .then((res) => {
          if (res.data.status === "ok") {
            showAlert("Success", "Order cancelled successfully");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            // this.props.destroyCartDetail();
            this.props.history.push("/checkout");
          } else {
            this.props.history.push("/checkout");
          }
        });
    }
  }

  sendNotification() {
    axios.get(apiUrlNotify + "order_notify").then((res) => {});
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
  };
  render() {
    let orderArr = this.props.orderdetail;
    var orderDetail = [];

    if (Object.keys(orderArr).length > 0) {
      if (orderArr[0].status === "ok") {
        orderDetail = orderArr[0].result_set[0];
      }
    }

    var availability_label = "Pickup";

    if (orderDetail.order_availability_id === deliveryId) {
      availability_label = "Delivery";
    } else if (orderDetail.order_availability_id === dineinId) {
      availability_label = "Dine in";
    } else if (orderDetail.order_availability_id === cateringId) {
      availability_label = "Event";
    }

    return (
      <div className="thankyou-main-div">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />
        <div className="container">
          {Object.keys(orderDetail).length > 0 && (
            <div className="innersection_wrap tnk-you">
              <div className="product-step-inner-white">
                <div className="product-step-inner">
                  <div className="product-step-top textcenter">
                    {this.state.step === 1 && (
                      <div className="product-step-top1">
                        <img
                          className="big-plane"
                          src={
                            this.state.enableRetry === true &&
                            this.state.starttoRetry === false
                              ? not_find_driver
                              : sending
                          }
                          alt="plane"
                        />
                        {this.state.enableRetry === true &&
                        this.state.starttoRetry === false ? (
                          <>
                            <h2>Sorry We Couldnt Find A Driver</h2>
                            <p>Sometimes drivers are busy on the road</p>
                          </>
                        ) : (
                          <>
                            <h2>Finding Driver</h2>
                            <p>
                              We are waiting for rider to accept your order.
                            </p>
                            {this.state.currentparenter === "lalamove" && (
                              <p>Trying to assign with Lalamove</p>
                            )}
                            {this.state.currentparenter === "grab" && (
                              <p>Trying to assign with GrabExpress</p>
                            )}
                          </>
                        )}
                      </div>
                    )}
                    {this.state.step === 2 && (
                      <div className="product-step-top2">
                        <img
                          className="payment-de"
                          src={paymentg}
                          alt="Payment"
                        />
                        <h2>Finalise Payment</h2>
                        <p>We are verifying your payment</p>
                      </div>
                    )}
                    {this.state.step === 3 && (
                      <div className="product-step-top3">
                        <img
                          className="complete-tick"
                          src={thanku}
                          alt="plane"
                        />
                        <h2>Order is Complete</h2>
                        <p>We are verifying your payment</p>
                      </div>
                    )}
                    {this.state.enableRetry === true && (
                      <div className="retry">
                        <a
                          href={void 0}
                          className="button"
                          onClick={this.assignretry.bind(this)}
                        >
                          {this.state.starttoRetry === true
                            ? "Retrying..."
                            : "Retry"}
                        </a>
                        <a
                          href={void 0}
                          className="button"
                          onClick={this.cancelOrder.bind(this)}
                        >
                          Cancel
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="product-step">
                    <ul className="progressbar">
                      <li className="active">
                        <a href={void 0}>
                          <figure>1</figure>
                        </a>
                      </li>
                      <li className={this.state.step === 2 ? "active" : ""}>
                        <a href={void 0}>
                          <figure>2</figure>
                        </a>
                      </li>
                      <li className="">
                        <a href={void 0}>
                          <figure>3</figure>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="thank-order-detaildiv">
                  <div className="tnk-delivery">
                    {(orderDetail.order_availability_id === deliveryId ||
                      orderDetail.order_availability_id === cateringId) && (
                      <div className="delivery-cart-div">
                        <div className="cart-direction">
                          <img
                            className="cart-direction-left"
                            src={scootyImg}
                            alt="Outlet Location"
                          />
                          <img
                            className="cart-direction-right"
                            src={cartMpImg}
                            alt="Delivery Location"
                          />
                        </div>
                        <div className="cart_row tnkorder-first">
                          <div className="order-hlhs text-left">
                            <h5>Order Handling By</h5>
                            <p>{stripslashes(orderDetail.outlet_name)}</p>
                            {orderDetail.order_outlet_category_id !== "2" && (
                              <p>
                                {addressFormat(
                                  orderDetail.outlet_unit_number1,
                                  orderDetail.outlet_unit_number2,
                                  orderDetail.outlet_address_line1,
                                  orderDetail.outlet_address_line2,
                                  orderDetail.outlet_postal_code
                                )}
                              </p>
                            )}
                          </div>
                          <div className="order-hrhs text-right">
                            <h5> {availability_label} Location</h5>
                            <p>
                              {addressFormat(
                                orderDetail.order_customer_unit_no1,
                                orderDetail.order_customer_unit_no2,
                                orderDetail.order_customer_address_line1,
                                orderDetail.order_customer_address_line2,
                                orderDetail.order_customer_postal_code
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {orderDetail.order_availability_id === dineinId ? (
                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <h2>{availability_label} Date & Time</h2>
                          <h4 className="checkoutDate">
                            {Moment(orderDetail.order_date).format(
                              "DD/MM/YYYY"
                            )}{" "}
                            {this.showOrdTimeSlot(orderDetail)}
                          </h4>
                        </div>
                        <div className="delivery_total_left delivery_total_right">
                          <h2>Table No</h2>
                          <h4 className="checkoutTime">
                            {orderDetail.order_table_number}
                          </h4>
                        </div>
                      </div>
                    ) : (
                      <div className="delivery_total delivery_total_number delivery_datetime_div">
                        <div className="delivery_total_left">
                          <h2>{availability_label} Date</h2>
                          <h4 className="checkoutDate">
                            {Moment(orderDetail.order_date).format(
                              "DD/MM/YYYY"
                            )}
                          </h4>
                        </div>
                        <div className="delivery_total_left delivery_total_right">
                          <h2>{availability_label} time</h2>
                          <h4 className="checkoutTime">
                            {this.showOrdTimeSlot(orderDetail)}
                          </h4>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var globalSettings = Array();
  if (Object.keys(state.settings).length > 0) {
    if (state.settings[0].status === "ok") {
      globalSettings = state.settings[0].result_set;
    }
  }

  return {
    settingsArr: globalSettings,
    orderdetail: state.orderdetail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getOrderDetail: (orderId) => {
      dispatch({ type: GET_ORDER_DETAIL, orderId });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Findingdriver);
