import React, { Component } from "react";
import cookie from "react-cookies";
import {
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../Helpers/Config";

class Refpage extends Component {
  constructor(props) {
    super(props);

    let slugtext =
      typeof this.props.match.params.slugtext !== "undefined"
        ? this.props.match.params.slugtext
        : "";
    let slugparam =
      typeof this.props.match.params !== "undefined"
        ? this.props.match.params
        : "";

    if (slugtext === "delivery") {
      cookie.save("triggerAvlPop", deliveryId, {
        path: "/",
      });
    } else if (slugtext === "pickup") {
      cookie.save("triggerAvlPop", pickupId, {
        path: "/",
      });
    } else if (slugtext === "catering") {
      cookie.save("triggerAvlPop", cateringId, {
        path: "/",
      });
    } else if (slugtext === "reservation") {
      cookie.save("triggerAvlPop", reservationId, {
        path: "/",
      });
    } else if (slugtext === "ordernow") {
      cookie.save("orderPopuptrigger", "Yes", {
        path: "/",
      });
    } else if (slugtext === "promopopup") {
      cookie.save("promoPopupTrigger", "Yes", {
        path: "/",
      });
    } else if (slugtext === "home" || slugtext === "myvouchers") {
    } else {
      cookie.remove("triggerAvlPop", { path: "/" });
      cookie.remove("orderPopuptrigger", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
    }

    if (slugtext === "gotomyresvr") {
      this.props.history.push("/myreservations");
    }
    if (slugtext === "myvouchers") {
      this.props.history.push("/myvouchers");
    } else if (slugtext === "menu" && slugparam !== "") {
      if (
        slugparam.slugtext3 !== "" &&
        typeof slugparam.slugtext3 !== undefined &&
        typeof slugparam.slugtext3 !== "undefined"
      ) {
        this.props.history.push(
          "/" +
            slugtext +
            "/" +
            slugparam.slugtext1 +
            "/" +
            slugparam.slugtext2 +
            "/" +
            slugparam.slugtext3
        );
      } else if (
        slugparam.slugtext2 !== "" &&
        typeof slugparam.slugtext2 !== undefined &&
        typeof slugparam.slugtext2 !== "undefined"
      ) {
        this.props.history.push(
          "/" + slugtext + "/" + slugparam.slugtext1 + "/" + slugparam.slugtext2
        );
      } else if (
        slugparam.slugtext1 !== "" &&
        typeof slugparam.slugtext1 !== undefined &&
        typeof slugparam.slugtext1 !== "undefined"
      ) {
        this.props.history.push("/" + slugtext + "/" + slugparam.slugtext1);
      } else {
        this.props.history.push("/" + slugtext);
      }
    } else {
      this.props.history.push("/");
    }
  }

  render() {
    return <div></div>;
  }
}

export default Refpage;
