/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_PRODUCT_LIST, SET_PRODUCT } from "../actions";
import { appId, apiUrlV2, deliveryId } from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";
import { format } from "date-fns";
export const watchGetProducts = function* () {
  yield takeEvery(GET_PRODUCT_LIST, workerGetProducts);
};
function* workerGetProducts({
  catslug,
  subcatslug,
  outletid,
  sortByField,
  sortByValue,
}) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");
    var sortBy = "";
    if (
      sortByField !== "" &&
      typeof sortByField !== undefined &&
      typeof sortByField !== "undefined" &&
      sortByValue !== "" &&
      typeof sortByValue !== undefined &&
      typeof sortByValue !== "undefined"
    ) {
      sortBy =
        "&order_by_field=" + sortByField + "&order_by_type=" + sortByValue;
    }
    var userID =
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
        ? "&fav_cust_id=" + cookie.load("UserId")
        : "";

    var deliveryDate =
      cookie.load("deliveryDate") == undefined ||
      cookie.load("deliveryDate") == ""
        ? ""
        : cookie.load("deliveryDate").split("/");
    var delivery_Date = "";
    if (deliveryDate !== "") {
      delivery_Date =
        deliveryDate[2] + "-" + deliveryDate[1] + "-" + deliveryDate[0];
    } else {
      delivery_Date = format(new Date(), "yyyy-MM-dd");
    }
    var spicelPride =
      "&specialprice_applicable=orderdate&order_datetxt=" + delivery_Date;
	  
	
	var deliveryDateVal = '';
	var deliveryTime = (cookie.load("deliveryTime") !== undefined)?cookie.load("deliveryTime"):'';
	var deliveryDateTxt = (cookie.load("deliveryDate") !== undefined)?cookie.load("deliveryDate"):'';
	if(deliveryDateTxt != '') {
		var deliveryDateArr = deliveryDateTxt.split("/");
		    deliveryDateVal = deliveryDateArr[2]+"-"+deliveryDateArr[1]+"-"+deliveryDateArr[0];
	}
	var advProTimeSlt = "&orderdate=" + deliveryDateVal + "&ordertime=" + deliveryTime;

    const uri =
      apiUrlV2 +
      "products/getAllProducts?app_id=" +
      appId +
      "&availability=" +
      availabilityId +
      "&category_slug=" +
      catslug +
      "&subcate_slug=" +
      subcatslug +
      "&outletId=" +
      orderOutletId +
      sortBy +
      userID +
      spicelPride + advProTimeSlt;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_PRODUCT, value: resultArr });
  } catch {
    console.log("Get Products Failed");
  }
}
