import React, { Component } from "react";
class Refpage extends Component {
  constructor(props) {
    super(props);
    
    this.props.history.push(
      "/catering/" +
        this.props.match.params.catSlug +
        "/" +
        this.props.match.params.subCatSlug
    );
  }

  render() {
    return <div></div>;
  }
}

export default Refpage;
