import { takeEvery, call, put } from 'redux-saga/effects';
import { GET_PICKUP_OUTLETS, SET_PICKUP_OUTLETS, GET_TABLEAVAILABILITY, SET_TABLEAVAILABILITY, GET_OUTLET_SUBCHARGE, SET_OUTLET_SUBCHARGE } from '../actions';
import { appId, apiUrl, deliveryId, pickupId, dineinId } from "../components/Helpers/Config";
import Axios from 'axios';
import cookie from "react-cookies";

export const watchGetPickupOutlets = function* () {
  yield takeEvery(GET_PICKUP_OUTLETS, workerGetPickupOutlets);
}

function* workerGetPickupOutlets() {
  try {
    const uri = apiUrl+'outlets/pickup_outlets?app_id='+appId+"&availability="+pickupId;
    const result = yield call(Axios.get, uri);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_PICKUP_OUTLETS, value: resultArr });
  } 
  catch {
    console.log('Get Pickup Outlets Failed');
  }
}

export const watchgetTableAvailability = function* () {
  yield takeEvery(GET_TABLEAVAILABILITY, workergetTableAvailability);
}

function* workergetTableAvailability({ formPayload }) {
  try {
      
    const result = yield call(getTableData, formPayload);
	var resultArr = [];
	resultArr.push(result.data);
    yield put({ type: SET_TABLEAVAILABILITY, value: resultArr });
  } 
  catch {
    console.log('Find table failed');
  }
} 
  

function getTableData(formPayload) {
  return Axios.post(apiUrl+'tablemanagement/find_table', formPayload);
} 

export const watchGetOutletSurcharge = function* () {
  yield takeEvery(GET_OUTLET_SUBCHARGE, workerGetOutletSurcharge);
}

function* workerGetOutletSurcharge({order_delivery_date, deliveryTime}) {
  try {
    var outlet_id = (cookie.load("orderOutletId") !== '' &&  cookie.load("orderOutletId") !== 'undefined' && cookie.load("orderOutletId") !== 'null' ? cookie.load("orderOutletId") : '');
    var availabilityIdTxt = cookie.load("defaultAvilablityId");
    var orderDate = '';
    var orderTime = '';

    if (availabilityIdTxt === deliveryId || availabilityIdTxt === dineinId) {
      orderDate = order_delivery_date
      orderTime = deliveryTime;
    }

    var urlPrms =
    "&availabilityid=" +
    availabilityIdTxt +
    "&outletid=" +
    outlet_id +
    "&orderdate=" +
    orderDate +
    "&ordertime=" +
    orderTime +
    "&";
    const uri =  apiUrl + "outlets/outletsubchargenew?app_id=" + appId + urlPrms;
    const result = yield call(Axios.get, uri);
	  var resultArr = [];
	  resultArr.push(result.data);
    yield put({ type: SET_OUTLET_SUBCHARGE, value: resultArr });
  } 
  catch {
    console.log('Get Outlets Sucharges Failed');
  }
}