import { SET_EVENTS, SET_EVENTDATA } from '../actions';

const events = (state = [], action) => {
  var obj = "";
  switch (action.type) {
    case SET_EVENTS:
      obj = { type: "SET_EVENTS" };
      return [...action.value, obj];
    case SET_EVENTDATA:
      obj = { type: "SET_EVENTDATA" };
      return [...action.value, obj]; 
    default: return state;
  }
}

export default events;