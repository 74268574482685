import { takeEvery, call, put } from "redux-saga/effects";
import { GET_OUTLETCATEGORIES, SET_OUTLETCATEGORIES } from "../actions";
import { appId, apiUrl } from "../components/Helpers/Config";
import Axios from "axios";

export const watchGetOutletCategories = function* () {
  yield takeEvery(GET_OUTLETCATEGORIES, workerGetOutletCategories);
};

function* workerGetOutletCategories() {
  try {
    const uri = apiUrl + "outlets/getOutletCategories?app_id=" + appId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_OUTLETCATEGORIES, value: resultArr });
  } catch {
    console.log("Get All Outlets Categories Failed");
  }
}
