/* eslint-disable */
import React, { Component } from "react";
import Slider from "react-slick";
import { showLoader } from "../Helpers/SettingHelper";
const settings = {
  infinite: false,
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: true,
  responsive: [
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
class Category extends Component {
  constructor(props) {
    super(props);
    this.state = {
      catSlug: this.props.match.params.catSlug,
      subCatSlug: this.props.match.params.subCatSlug,
      showCategoryhidden: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.subCatSlug !== nextProps.match.params.subCatSlug) {
      this.setState({ subCatSlug: nextProps.match.params.subCatSlug });
    }
  }

  categorylist() {
    let categoryData = [];
    if (this.props.categoryData.length > 0) {
      categoryData = this.props.categoryData[0].result_set;
    }

    var i = 0;
    return (
      <div className="products_sidebar_inner">
        <Slider {...settings}>
          {(categoryData || []).map((category) => {
            {
              if (category.pro_cate_slug === this.state.catSlug) {
                return (category.subcategory || []).map(
                  (subcategory, index) => {
                    if (index <= 3) {
                      return (
                        <div
                          className={
                            subcategory.pro_subcate_slug ===
                            this.state.subCatSlug
                              ? "products_sidebar_content active"
                              : "products_sidebar_content"
                          }
                          key={index}
                        >
                          <a
                            href={void 0}
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeChangeCategory(
                                index,
                                category,
                                subcategory,
                                ""
                              );
                            }}
                          >
                            <span>{subcategory.pro_subcate_name}</span>
                          </a>
                        </div>
                      );
                    }
                  }
                );
              }
            }
          })}
        </Slider>
        <ul className="no-slide-res">
          {(categoryData || []).map((category) => {
            {
              if (category.pro_cate_slug === this.state.catSlug) {
                return (category.subcategory || []).map(
                  (subcategory, index) => {
                    if (index <= 3) {
                      return (
                        <li
                          className={
                            subcategory.pro_subcate_slug ===
                            this.state.subCatSlug
                              ? "products_sidebar_content active"
                              : "products_sidebar_content"
                          }
                          key={index}
                        >
                          <a
                            href={void 0}
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeChangeCategory(
                                index,
                                category,
                                subcategory,
                                ""
                              );
                            }}
                          >
                            <span>{subcategory.pro_subcate_name}</span>
                          </a>
                        </li>
                      );
                    }
                  }
                );
              }
            }
          })}
        </ul>
      </div>
    );
  }

  categoryListHidden() {
    let categoryData = [];
    if (this.props.categoryData.length > 0) {
      categoryData = this.props.categoryData[0].result_set;
    }
    var i = 0;
    const category_menu = categoryData.map((item, index) => {
      return (
        <div key={index}>
          {(categoryData || []).map((category, key) => {
            {
              if (category.subcategory.length > 4) {
                i++;
                $(
                  ".products-sidebar-more-menu-parent, .products_sidebar_more_categor_info"
                ).addClass("active");

                if (category.pro_cate_slug === this.state.catSlug) {
                  return (category.subcategory || []).map(
                    (subcategory, index) => {
                      return (
                        <div className="menu-list-intvl">
                          <a
                            href={void 0}
                            className="menu-title-link"
                            onClick={(e) => {
                              e.preventDefault();
                              this.changeChangeCategory(
                                index,
                                category,
                                subcategory,
                                subcategory.pro_subcate_name
                              );
                            }}
                          >
                            {subcategory.pro_subcate_name}
                          </a>
                        </div>
                      );
                    }
                  );
                }
              }
            }
          })}
        </div>
      );
    });

    if (i > 0) {
      $(".products-sidebar-more-menu").show();
    } else {
      $(".products-sidebar-more-menu").hide();
    }
    return category_menu;
  }

  changeChangeCategory(index, category, subcategory, category_name) {
    if (category_name !== "") {
      $(".products-sidebar-more-menu .category_change_name").html(
        category_name
      );
      this.props.history.push(
        "/catering/" +
          category.pro_cate_slug +
          "/" +
          subcategory.pro_subcate_slug
      );

      $(".products-sidebar-more-menu-parent").addClass("sub-active");
    } else {
      this.props.history.push(
        "/catering/" +
          category.pro_cate_slug +
          "/" +
          subcategory.pro_subcate_slug
      );
      $(".products_sidebar_more_categor_info").hide();
      $(".products-sidebar-more-menu-parent").removeClass("sub-active");
      $(".products-sidebar-more-menu .category_change_name").html("CHECK MORE");
    }
    showLoader("product-lhs-inner", "class");
  }

  openCategoryHidden(e) {
    e.preventDefault();

    if (this.state.showCategoryhidden === false) {
      this.setState({
        showCategoryhidden: true,
      });
      $(".products_sidebar_more_categor_info").show();
    } else {
      this.setState({
        showCategoryhidden: false,
      });
      $(".products_sidebar_more_categor_info").hide();
    }
  }

  render() {
    return (
      <>
        {this.categorylist()}
        <div
          className="products-sidebar-more-menu"
          style={{ display: "none" }}
          onClick={(e) => this.openCategoryHidden(e)}
        >
          <div className="products-sidebar-more-menu-parent">
            <span className="more-menu-name category_change_name">
              CHECK MORE &nbsp;
            </span>
            <label className="products_sidebar_open_more_category">
              <i className="fa fa-angle-double-down"></i>
            </label>
          </div>
          <ul
            style={{ display: "none" }}
            className="products_sidebar_more_categor_info"
          >
            {this.categoryListHidden()}
          </ul>
        </div>
      </>
    );
  }
}

export default Category;
