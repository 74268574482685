import { SET_TABLEAVAILABILITY } from '../actions';

const tableavailability = (state = [], action) => {
    switch (action.type) {
        case SET_TABLEAVAILABILITY:
        return [...action.value];
        default: return state;
    }
}

export default tableavailability;