/* eslint-disable */
import React, { Component } from "react";
import cookie from "react-cookies";
import axios from "axios";
import { connect } from "react-redux";
import update from "immutability-helper";

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import PantryProducts from "../Home/HomeSubCompo/PantryProducts";

import {
  appId,
  apiUrl,
  deliveryId,
  pickupId,
  dineinId,
  cateringId,
  reservationId,
  companyname,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_STATIC_BLOCK,
  GET_NORMAL_POPUP,
  GET_CATEGORY_LIST,
  GET_MENU_NAVIGATION,
} from "../../actions";
import {
  showLoader,
  hideLoader,
  validateEmailFun,
  showCustomAlert,
  stripslashes,
} from "../Helpers/SettingHelper";

import newletterImg from "../../common/images/newletter-bg.jpg";
import grambig from "../../common/images/gram-big.jpg";
import gram1 from "../../common/images/gram1.jpg";
import gram2 from "../../common/images/gram2.jpg";
import gram3 from "../../common/images/gram3.jpg";
import gram4 from "../../common/images/gram4.jpg";

import db from "../../common/images/delivery-bike.svg";
import dbwhite from "../../common/images/delivery-bike-white.svg";
import ta from "../../common/images/takeaway.svg";
import tawhite from "../../common/images/takeaway-white.svg";
import din from "../../common/images/dinein.svg";
import dinwhite from "../../common/images/dinein-white.svg";
import res from "../../common/images/reservation.svg";
import reswhite from "../../common/images/reservation-white.svg";
import cater from "../../common/images/catering.svg";
import caterwhite from "../../common/images/catering-white.svg";
import locpin from "../../common/images/location-pin.svg";
import locpinwhite from "../../common/images/location-pin-white.svg";
import homefeed from "../../common/images/insta-lhs.jpg";
import homefeed5 from "../../common/images/insta-rhs.jpg";
import homefeed1 from "../../common/images/insta-top1.jpg";
import homefeed2 from "../../common/images/insta-top2.jpg";
import homefeed3 from "../../common/images/instabtm1.jpg";
import homefeed4 from "../../common/images/insta-btm2.jpg";

import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
var Parser = require("html-react-parser");
const settingsJourney = {
  items: 1,
  autoplay: false,
  loop: true,
  nav: false,
};
const settingsGallery = {
  items: 3,
  autoplay: false,
  loop: false,
  nav: false,
};
class Home extends Component {
  constructor(props) {
    super(props);

    var normalpopupFlg = "initial";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes" ||
      cookie.load("promoPopupTrigger") === "Yes"
    ) {
      normalpopupFlg = "trigger";
    }

    this.state = {
      users: [],
      nextavail: "",
      cartTriggerFlg: "No",
      staticblacks: [],
      homeAboutBlk: "",
      homePantryBlk: "",
      homepromoBlk: "",
      homeBlogBlk: "",
      homejournyBlk: "",
      homejournyimgBlk: [],
      homejournyimgpath: "",
      homegrammedBlk: "",
      homecafe: "",
      homesubscriptionblk: "",
      homeblksorting: "",
      viewProductFlg: "no",
      viewProductSlug: "",
      normalpopuplist: [],
      normalpopupdata: [],
      normalpopup_status: "no",
      normalpopup_flg: normalpopupFlg,
      normalpopupstatus: "initial",
      promo_mail_id: "",
      displayType: "all",
      newsletterfields: {
        email: "",
        firstname: "",
        lastname: "",
        terms: "",
      },
      promomail_error: "",
      homeGallery: []
    };

    this.props.getSettings();
    this.props.getCategoryList();
    this.props.getMenuNavigation();
    this.props.getNormalPopup();
    this.props.getStaticBlock();
  }

  componentWillReceiveProps(PropsData) {
    if (PropsData.staticblack !== this.state.staticblacks) {
      var homeAboutBlk = "",
        homePantryBlk = "",
        homepromoBlk = "",
        homeBlogBlk = "",
        homejournyBlk = "",
        homejournyimgpath = "",
        homegrammedBlk = "",
        homecafe = "",
        homeblksorting = "",
        homesubscriptionblk = "";
      var homejournyimgBlk = [];
      var homeGallery = [];
      if (Object.keys(PropsData.staticblack).length > 0) {
        PropsData.staticblack.map((data) => {
          if (data.staticblocks_slug === "home-about-us") {
            homeAboutBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-pantry") {
            homePantryBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-promotion") {
            homepromoBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-blog-post") {
            homeBlogBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-our-journey") {
            homejournyBlk = data.staticblocks_description;
            homejournyimgBlk = data.gallery_images;
            homejournyimgpath = data.gallery_image_path;
            return "";
          }
          if (data.staticblocks_slug === "home-journey-grammed") {
            homegrammedBlk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-order-now") {
            homecafe = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-block-sorting") {
            homeblksorting = data.staticblocks_description;
            homeblksorting = homeblksorting.replace("<p>", "");
            homeblksorting = homeblksorting.replace("</p>", "");
            return "";
          }
          if (data.staticblocks_slug === "home-subscription") {
            homesubscriptionblk = data.staticblocks_description;
            return "";
          }
          if (data.staticblocks_slug === "home-gallery") {
            homeGallery = data;
            return "";
          }

        });
      }
      homeAboutBlk =
        homeAboutBlk !== "" && homeAboutBlk !== null
          ? Parser(homeAboutBlk)
          : homeAboutBlk;
      homePantryBlk =
        homePantryBlk !== "" && homePantryBlk !== null
          ? Parser(homePantryBlk)
          : homePantryBlk;
      homepromoBlk =
        homepromoBlk !== "" && homepromoBlk !== null
          ? Parser(homepromoBlk)
          : homepromoBlk;
      homeBlogBlk =
        homeBlogBlk !== "" && homeBlogBlk !== null
          ? Parser(homeBlogBlk)
          : homeBlogBlk;
      homejournyBlk =
        homejournyBlk !== "" && homejournyBlk !== null
          ? Parser(homejournyBlk)
          : homejournyBlk;
      homegrammedBlk =
        homegrammedBlk !== "" && homegrammedBlk !== null
          ? Parser(homegrammedBlk)
          : homegrammedBlk;
      homecafe =
        homecafe !== "" && homecafe !== null ? Parser(homecafe) : homecafe;
      homesubscriptionblk =
        homesubscriptionblk !== "" && homesubscriptionblk !== null
          ? Parser(homesubscriptionblk)
          : homesubscriptionblk;

      this.setState({
        staticblacks: PropsData.staticblack,
        homeAboutBlk: homeAboutBlk,
        homePantryBlk: homePantryBlk,
        homepromoBlk: homepromoBlk,
        homeBlogBlk: homeBlogBlk,
        homejournyBlk: homejournyBlk,
        homejournyimgBlk: homejournyimgBlk,
        homejournyimgpath: homejournyimgpath,
        homegrammedBlk: homegrammedBlk,
        homecafe: homecafe,
        homeblksorting: homeblksorting,
        homesubscriptionblk: homesubscriptionblk,
        homeGallery: homeGallery,
      });
    }

    if (
      PropsData.normalpopuplist !== this.state.normalpopuplist &&
      this.state.normalpopup_flg === "initial"
    ) {
      var normalpopupMain = PropsData.normalpopuplist,
        normalpopupdata = [],
        normalpopupStatus = "no";
      if (normalpopupMain !== "") {
        if (Object.keys(normalpopupMain).length > 0) {
          var normalpopupIds = cookie.load("normalpopupIds");
          var normalpopupIdArr =
            normalpopupIds != "" && normalpopupIds != undefined
              ? normalpopupIds.split("~~")
              : Array();
          if (
            $.inArray(normalpopupMain[0].normalpopup_id, normalpopupIdArr) ===
            -1
          ) {
            normalpopupdata = normalpopupMain[0];
            normalpopupStatus = "yes";
          }
          this.setState({
            normalpopuplist: normalpopupMain,
            normalpopupdata: normalpopupdata,
            normalpopup_status: normalpopupStatus,
            normalpopup_flg: "trigger",
          });
        }
      }
    }

    if (PropsData.normalpopupstatus !== this.state.normalpopupstatus) {
      this.setState({ normalpopupstatus: PropsData.normalpopupstatus });
    }
  }

  chooseAvailability(availability) {
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/catering");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /* popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  componentDidMount() {
    if (
      cookie.load("triggerAvlPop") != "" &&
      cookie.load("triggerAvlPop") != undefined
    ) {
      var availabilityId = cookie.load("triggerAvlPop");
      cookie.remove("triggerAvlPop", { path: "/" });
      this.chooseAvailability(availabilityId);
    }

    if (cookie.load("orderPopuptrigger") === "Yes") {
      cookie.remove("orderPopuptrigger", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#order-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("loginpopupTrigger") === "Yes") {
      cookie.save("loginpopupTrigger", "fromcheckout", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }

    if (cookie.load("rfcodeAction") === "Yes") {
      cookie.remove("rfcodeAction");
      $.magnificPopup.open({
        items: {
          src: "#signup-popup",
        },
        type: "inline",
      });
    }

    var RctThis = this;
    $("body")
      .off("click", ".act_order_popup")
      .on("click", ".act_order_popup", function (e) {
        e.preventDefault();

        var odrType = $(this).attr("data-acturl");
        var availabilityId = "";
        if (odrType === "delivery") {
          availabilityId = deliveryId;
        } else if (odrType === "pickup") {
          availabilityId = pickupId;
        } else if (odrType === "catering") {
          availabilityId = cateringId;
        } else if (odrType === "reservation") {
          availabilityId = reservationId;
        }

        if (availabilityId !== "") {
          RctThis.chooseAvailability(availabilityId);
        } else if (availabilityId === "" && odrType === "ordernow") {
          $.magnificPopup.open({
            items: {
              src: "#ordernowpopup",
            },
            type: "inline",
          });
        }
      });

    $(document).ready(function () {
      $(document).on("click", ".home-about-us-dots", function () {
        $(".home-about-us-dots").hide();
        $(".home-about-us-more").show();
      });

      $(document).on("click", ".home-about-us-less", function () {
        $(".home-about-us-more").hide();
        $(".home-about-us-dots").show();
      });

      $(document).on("click", ".home-our-journey-dots", function () {
        $(".home-our-journey-dots").hide();
        $(".home-our-journey-more").show();
      });

      $(document).on("click", ".home-our-journey-less", function () {
        $(".home-our-journey-more").hide();
        $(".home-our-journey-dots").show();
      });
    });
  }

  componentDidUpdate() {
    // $(document).ready(function() {
    //   $('.home-about-us-more').hide();
    //   $('.home-our-journey-more').hide();
    // })
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductFlg: "yes", viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "view_pro_upate" && value !== "") {
      this.setState({ viewProductFlg: value });
    }
    if (field === "firstcategory" && value !== "") {
      this.setState({ firstcategory: value });
    }
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ backdrop: "static", keyboard: false });
  }

  triggerNormalPopup(trgType) {
    var otherPageActTrigger = "no";
    if (
      (cookie.load("triggerAvlPop") != "" &&
        cookie.load("triggerAvlPop") != undefined) ||
      cookie.load("orderPopuptrigger") === "Yes" ||
      cookie.load("loginpopupTrigger") === "Yes"
    ) {
      otherPageActTrigger = "yes";
    }

    if (
      trgType === "loading" &&
      otherPageActTrigger === "no" &&
      this.state.normalpopup_status === "yes" &&
      Object.keys(this.state.normalpopupdata).length > 0 &&
      cookie.load("promoPopupTrigger") !== "Yes"
    ) {
      var normalpopupIds = cookie.load("normalpopupIds");
      var normalpopupIdsNew =
        normalpopupIds != "" && normalpopupIds != undefined
          ? normalpopupIds + "~~" + this.state.normalpopupdata.normalpopup_id
          : this.state.normalpopupdata.normalpopup_id;
      var normalpopupIdArr = [];
      normalpopupIdArr["normalpopupids"] = normalpopupIdsNew;
      cookie.save("normalpopupIds", normalpopupIdsNew, { path: "/" });
      var $_this_rec = this;
      $.magnificPopup.open({
        items: {
          src: "#normal-popup",
        },
        type: "inline",
        midClick: true,
        closeOnBgClick: false,
        callbacks: {
          close: function () {
            $_this_rec.normalPopupUpdate();
          },
        },
      });
    }

    if (
      (cookie.load("promoPopupTrigger") === "Yes" ||
        (otherPageActTrigger === "no" &&
          this.state.normalpopup_status === "no" &&
          Object.keys(this.state.normalpopupdata).length === 0)) &&
      cookie.load("mailpopopuptrg") !== "yes"
    ) {
      cookie.save("mailpopopuptrg", "yes", { path: "/" });
      cookie.remove("promoPopupTrigger", { path: "/" });
      var globalsettings = this.props.globalsettings;
      if (Object.keys(globalsettings).length > 0) {
        if (globalsettings[0].result_set.client_promocode_options === "1") {
          $.magnificPopup.open({
            items: {
              src: "#promo-check-popup",
            },
            type: "inline",
            midClick: true,
            closeOnBgClick: false,
          });
        }
      }
    }
  }

  normalPopupUpdate() {
    if (cookie.load("mailpopopuptrg") !== "yes") {
      this.props.history.push("/refpage/promopopup");
    }
  }

  handleEmailChange(event) {
    this.setState({ promo_mail_id: event.target.value, promomail_error: "" });
  }

  sendPromoMailFun() {
    var promoEmail = this.state.promo_mail_id;

    var mailErrorTxt = "";
    if (promoEmail === "") {
      mailErrorTxt = "Email Address is required.";
    } else if (!validateEmailFun(promoEmail)) {
      mailErrorTxt = "Invalide Email Address";
    }

    if (mailErrorTxt !== "") {
      this.setState({ promomail_error: mailErrorTxt });
      return false;
    } else {
      showLoader("promomailpopup-cls", "class");

      var qs = require("qs");
      var postObject = {
        app_id: appId,
        email_address: promoEmail,
      };

      axios
        .post(apiUrl + "promotion/user_promotion", qs.stringify(postObject))
        .then((response) => {
          hideLoader("promomailpopup-cls", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            showCustomAlert(
              "success",
              "You are now a Member of " +
                companyname +
                ". Please check your email for more information."
            );
          } else {
            var errMsgtxt =
              response.data.message !== ""
                ? response.data.message
                : "Sorry! You didn`t have promo code.";
            showCustomAlert("error", errMsgtxt);
          }
          return false;
        });
    }

    return false;
  }

  setdisplayType(displayType) {
    this.setState({ displayType: displayType });
  }
  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");

    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (defaultAvilTy === cateringId && cartTotalItems > 0) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      } else if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    } else if (
      defaultAvilTy === availability &&
      cookie.load("orderOutletId") != "" &&
      cookie.load("orderOutletId") != undefined
    ) {
      /*$.magnificPopup.close();
			this.props.history.push('/products');
			return false;*/
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-popup";
    } else if (availability === pickupId) {
      popupIdtxt = "#takeaway-popup";
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
      /*popupIdtxt = '#comingsoon-popup';*/
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }
  checkActiveDivHd(avlType) {
    var clsTxt = "";
    var availability = cookie.load("defaultAvilablityId");
    if (availability == avlType) {
      clsTxt += "active";
    }
    return clsTxt;
  }
  openOrders(type, event) {
    event.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#delevery-postcode-popup",
      },
      type: "inline",
    });
  }

  
  fieldChange = (field, value) => {
    this.setState(
      update(this.state, { newsletterfields: { [field]: { $set: value } } })
    );
  };

  submitNewsletter = () => {
    const formPayload = this.state.newsletterfields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      firstname: formPayload.firstname,
      lastname: formPayload.lastname,
      email: formPayload.email,
      terms: formPayload.terms,
    };
    showLoader("subscribe_submit", "class");
    axios
      .post(apiUrl + "newsletter/subscription", qs.stringify(postObject))
      .then((response) => {
        hideLoader("subscribe_submit", "class");
        $.magnificPopup.close();
        if (response.data.status === "ok") {
          showCustomAlert("success", response.data.message);
          if (
            response.data.message !==
            "Your Email was already subscribed to the Newsletter."
          ) {
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          }
        } else {
          var errMsgtxt =
            response.data.message !== ""
              ? response.data.message
              : "Something went wrong! Try after sometime.";
          showCustomAlert("error", errMsgtxt);
        }
        return false;
      });
  };

  availabilityList() {
    var settingsArr = this.props.globalsettings;
    if (Object.keys(settingsArr).length > 0) {
      var settingsRes = settingsArr[0].result_set.availability;
      var client_theme = settingsArr[0].result_set.client_theme
      if (settingsRes.length > 0) {
        return settingsRes.map((item, index) => {
          var avilclass = "";
          if (item.availability_id === deliveryId) {
            avilclass = "hci-one";
          } else if (item.availability_id === pickupId) {
            avilclass = "hci-two";
          } else if (item.availability_id === dineinId) {
            avilclass = "hci-three";
          } else if (item.availability_id === reservationId) {
            avilclass = "hci-four";
          } else if (item.availability_id === cateringId) {
            avilclass = "hci-five";
          }
          return (
            <li key={index} className={avilclass}>
              <a
                href="/"
                onClick={this.setAvailabilityFun.bind(
                  this,
                  item.availability_id
                )}
                className={this.checkActiveDivHd(item.availability_id)}
              >
                {(() => {
                  if (item.availability_id === deliveryId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (<> <img className="hci-normal" src={db} />{" "}
                            <img className="hci-hover" src={dbwhite} /> </>)
                          } else if (client_theme == 1) {
                            return (<> <img className="hci-normal" src={dbwhite} />{" "}
                            <img className="hci-hover" src={dbwhite} /> </>)
                          } else if (client_theme == 2) {
                            return (<> <img className="hci-normal" src={db} />{" "}
                            <img className="hci-hover" src={dbwhite} /> </>)
                          }
                        })()}
                        
                      </div>
                    );
                  } else if (item.availability_id === pickupId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (<> <img className="hci-normal" src={ta} />{" "}
                            <img className="hci-hover" src={tawhite} /> </>)
                          } else if (client_theme == 1) {
                            return (<> <img className="hci-normal" src={tawhite} />{" "}
                            <img className="hci-hover" src={tawhite} /> </>)
                          } else if (client_theme == 2) {
                            return (<> <img className="hci-normal" src={tawhite} />{" "}
                            <img className="hci-hover" src={tawhite} /> </>)
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === dineinId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (<> <img className="hci-normal" src={din} />{" "}
                            <img className="hci-hover" src={dinwhite} /> </>)
                          } else if (client_theme == 1) {
                            return (<> <img className="hci-normal" src={dinwhite} />{" "}
                            <img className="hci-hover" src={dinwhite} /> </>)
                          } else if (client_theme == 2) {
                            return (<> <img className="hci-normal" src={din} />{" "}
                            <img className="hci-hover" src={dinwhite} /> </>)
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === reservationId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (<> <img className="hci-normal" src={res} />{" "}
                            <img className="hci-hover" src={reswhite} /> </>)
                          } else if (client_theme == 1) {
                            return (<> <img className="hci-normal" src={reswhite} />{" "}
                            <img className="hci-hover" src={reswhite} /> </>)
                          } else if (client_theme == 2) {
                            return (<> <img className="hci-normal" src={res} />{" "}
                            <img className="hci-hover" src={reswhite} /> </>)
                          }
                        })()}
                      </div>
                    );
                  } else if (item.availability_id === cateringId) {
                    return (
                      <div>
                        {(() => {
                          if (client_theme == 0) {
                            return (<> <img className="hci-normal" src={cater} />{" "}
                            <img className="hci-hover" src={caterwhite} /> </>)
                          } else if (client_theme == 1) {
                            return (<> <img className="hci-normal" src={caterwhite} />{" "}
                            <img className="hci-hover" src={caterwhite} /> </>)
                          } else if (client_theme == 2) {
                            return (<> <img className="hci-normal" src={cater} />{" "}
                            <img className="hci-hover" src={caterwhite} /> </>)
                          }
                        })()}
                      </div>
                    );
                  }
                })()}

                {item.availability_name}
              </a>
            </li>
          );
        });
      }
    }
  }

  setAvailabilityFun(availability, event) {
    event.preventDefault();
    if (availability === cateringId) {
      return false;
    }
    this.setState(
      { setAvilablityId: availability, error_order_type: "" },
      () => {
        this.chooseAvailabilityFun(availability, event);
      }
    );
  }

  chooseAvailabilityFun(availability, event) {
    event.preventDefault();
    var defaultAvilTy = cookie.load("defaultAvilablityId");
    if (availability !== reservationId && availability !== cateringId) {
      // if (availability === defaultAvilTy) {
      //   this.props.history.push("/menu");
      //   return false;
      // }
    }
    if (defaultAvilTy !== availability) {
      var cartTotalItems = cookie.load("cartTotalItems");
      cartTotalItems =
        cartTotalItems != "" && cartTotalItems != undefined
          ? parseInt(cartTotalItems)
          : 0;
      if (
        cookie.load("orderOutletId") != "" &&
        cookie.load("orderOutletId") != undefined
      ) {
        this.setState({ nextavail: availability });
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }
    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#delevery-postcode-popup";
    } else if (availability === pickupId) {
      /*  if (
        this.state.pickupOutlets !== "" &&
        typeof this.state.pickupOutlets !== undefined &&
        typeof this.state.pickupOutlets !== undefined
      ) {
        if (this.state.pickupOutlets.length === 1) {
          this.pickOutlet(this.state.pickupOutlets[0]);
        } else {
          popupIdtxt = "#takeaway-popup";
        }
      } else { */
      popupIdtxt = "#takeaway-popup";
      /*  } */
    } else if (availability === cateringId) {
      cookie.save("defaultAvilablityId", cateringId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/package");
      return false;
    } else if (availability === reservationId) {
      cookie.save("defaultAvilablityId", reservationId, { path: "/" });
      $.magnificPopup.close();
      this.props.history.push("/reservation");
      return false;
    } else if (availability === dineinId) {
      cookie.save("defaultAvilablityId", dineinId, { path: "/" });
      $.magnificPopup.close();
      popupIdtxt = "#dinein-popup";
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  render() {
    let settingsArr = this.props.globalsettings;
    var showPromoPopup = "",
      showNormalPopup = "",client_theme = 0;
    if (Object.keys(settingsArr).length > 0) {
      if (Object.keys(settingsArr[0].result_set).length > 0) {
        var client_theme = settingsArr[0].result_set.client_theme
        if (settingsArr[0].result_set.client_promocode_enable === "1") {
          showNormalPopup =
            settingsArr[0].result_set.client_promo_code_normal_popup_enable ===
            "1"
              ? "yes"
              : "";
          showPromoPopup =
            settingsArr[0].result_set.client_promocode_options === "1"
              ? "yes"
              : "";
        }
      }
    }

    return (
      <div className="home-main-div">
        {/* Header section */}
        <Header
          homePageState={this.state}
          cartTriggerFlg={this.state.cartTriggerFlg}
          sateValChange={this.sateValChange}
        />

        <section className="home-pantry">
                <PantryProducts
                  {...this.props}
                  sateValChange={this.sateValChange}
                  homePantryBlk={this.state.homePantryBlk}
                />
              </section>
      
        {/* Footer section */}
        <Footer />
     

        {this.state.normalpopupstatus !== "initial" &&
          (showNormalPopup === "yes" || showPromoPopup === "yes") &&
          this.triggerNormalPopup("loading")}
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }

  var normalPopupStatus = "initial";
  var normalPopupArr = Array();
  if (Object.keys(state.normalpopuplist).length > 0) {
    if (state.normalpopuplist[0].status === "ok") {
      normalPopupArr = state.normalpopuplist[0].result_set;
      normalPopupStatus = "ok";
    } else {
      normalPopupStatus = "error";
    }
  }

  var tempArr = Array();
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
      }
    }
  }

  return {
    globalsettings: state.settings,
    categorylist: state.categorylist,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    staticblack: blacksArr,
    normalpopuplist: normalPopupArr,
    normalpopupstatus: normalPopupStatus,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getCategoryList: () => {
      dispatch({ type: GET_CATEGORY_LIST });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getNormalPopup: () => {
      dispatch({ type: GET_NORMAL_POPUP });
    },
    getMenuNavigation: () => {
      dispatch({ type: GET_MENU_NAVIGATION });
    },
  };
};

export default connect(mapStateTopProps, mapDispatchToProps)(Home);
