/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_CARTDATEDATA, SET_CARTDATEDATA } from "../actions";
import {
  appId,
  apiUrl,
  apiUrlCtrng,
  cateringId,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetPackageDate = function* () {
  yield takeEvery(GET_CARTDATEDATA, workerGetPackageDate);
};

function* workerGetPackageDate({ slug }) {
  try {
    const uri =
      apiUrlCtrng +
      "catering/availableAdvancedDates?app_id=" +
      appId +
      "&availability_id=" +
      cateringId +
      "&dateFlagkey=START&outletId=" +
      cookie.load("orderOutletId");
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    yield put({ type: SET_CARTDATEDATA, value: resultArr });
  } catch {
    console.log("Get Page Failed");
  }
}
