/* eslint-disable */
import { takeEvery, call, put } from "redux-saga/effects";
import { GET_MENU_NAVIGATION, SET_MENU_NAVIGATION } from "../actions";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
} from "../components/Helpers/Config";
import Axios from "axios";
import cookie from "react-cookies";

export const watchGetMenuNavigation = function* () {
  yield takeEvery(GET_MENU_NAVIGATION, workerGetMenuNavigation);
};

function* workerGetMenuNavigation(params) {
  try {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");
    if (
      params.outletID !== "" &&
      typeof params.outletID !== undefined &&
      typeof params.outletID !== "undefined"
    ) {
      var orderOutletId = params.outletID;
    } else {
      var orderOutletId =
        cookie.load("orderOutletId") === undefined ||
        cookie.load("orderOutletId") === "" ||
        cookie.load("orderOutletId") === "0" ||
        cookie.load("orderOutletId") === "null"
          ? ""
          : cookie.load("orderOutletId");
    }

    const uri =
      apiUrlV2 +
      "products/getMenuNavigation?app_id=" +
      appId +
      "&availability=" +
      availabilityId +
      "&outletId=" +
      orderOutletId;
    const result = yield call(Axios.get, uri);
    var resultArr = [];
    resultArr.push(result.data);
    if (result.data.status == "ok") {
      cookie.save("firstNavigation", result.data.result_set[0].pro_cate_slug, {
        path: "/",
      });
    }
    yield put({ type: SET_MENU_NAVIGATION, value: resultArr });
  } catch (e) {
    console.log(e, "Get Menu Navigation Failed");
  }
}
